* {
  box-sizing: border-box;
}

.grid-square {
  border: solid 1px #777;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: default;
  user-select: none;
}

.combat-grid {
  display: grid;
  border: 1px #777 solid;
  grid-gap: 0.8px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 10px;
}

.player-symbol {
  color: rgb(41, 108, 185);
  font-size: 17px;
  cursor: pointer;
}

.enemy-symbol {
  color: rgb(224, 55, 55);
  font-size: 17px;
  cursor: crosshair;
}
.li-menu-item {
  cursor: pointer;
}

#combat-window {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid #777;
  flex: 1;
  max-height: 95vh;
}
#combat-info {
  width: 100%;
  border-collapse: collapse;
}

#combat-info th,
#combat-info td {
  padding: 5px;
  border: 1px solid #ccc;
  text-align: center;
}
#combat-info th {
  background-color: #555;
  color: white;
}
.combat-info-window {
  margin-right: 5px;
  margin-top: 5px;
}

#combat-info-container {
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
  border: 1px solid #777;
  padding: 5px;
}
.turn-order-container {
  padding: 5px;
  background-color: #777;
  height: 20%;
  width: 160px;
  border: 1px solid #555;
  max-height: 100px;
  overflow-y: scroll;
}
.turn-order-container ul {
  margin-left: -40px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.turn-order-header {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: white;
}
.turn-order-item {
  font-size: 11px;
  padding: 2px;
  border: 1px solid #333;
}
.enemy-clicked-menu-container {
  border: 1px solid white;
  background-color: #333;
  opacity: 80%;
}
.resolve-attack-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background-color: #777;
  width: 200px;
  margin-top: 10px;
  border: 2px solid white;
  color: #333;
}

.resolve-attack-window button {
  height: 40px;
  width: -webkit-fill-available;
  border: none;
  background-color: #555;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.resolve-attack-window button:hover {
  background-color: #333;
}
#attack-result {
  text-align: center;
}
#damage-result {
  text-align: center;
}
#end-turn-button,
#flee-button {
  width: 60px;
  height: 40px;
  border: none;
  background-color: #777;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
#end-turn-button:disabled,
#flee-button:disabled {
  background-color: #ccc;
  color: bisque;
  cursor: not-allowed;
}

button:hover {
  background-color: #999;
}

/* Mobile Stuff */
@media (max-width: 768px) {
  #combat-window {
    flex-direction: column;
    width: 100%;
    max-height: fit-content;
  }
  .combat-grid {
    padding: 25px;
    margin-right: 0px;
  }
  #combat-info-container {
    margin-right: 0px;
  }

  .turn-order-container {
    width: 160px;
    margin-top: 0;
  }

  .resolve-attack-window {
    width: 200px;
  }
}
