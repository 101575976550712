.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99; /* Add a higher z-index value */
}

.loading-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#loading-info {
  position: absolute;
  bottom: 25%;
  right: 30%;
}

.loading canvas {
  height: 200px;
  width: 200px;
  z-index: 1; /* Add a higher z-index value than the loading-content div */
}

.loading .loading-content {
  position: absolute; /* Change to absolute positioning */
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Center the div horizontally and vertically */
  z-index: 2; /* Add a higher z-index value than the canvas element */
  width: 200px;
  height: 200px;
}
