body {
  background-color: #333;
  color: #fff;
  font-family: sans-serif;
}

a {
  color: coral;
}

#main-container {
  display: flex;
  flex-wrap: wrap;
  height: 95vh;
}

#chat-container {
  height: 100%;
  width: 80%;
  padding: 5px;
  border: 1px solid #777;
  display: flex;
  flex-direction: column;
  flex: 9;
}

#chat-history {
  overflow-y: scroll;
  padding: 20px;
  scrollbar-width: thin;
  flex: 9;
}

#chat-form {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

#chat-input {
  height: 40px;
  width: 100%;
  border: none;
  background-color: #555;
  color: #fff;
  font-size: 16px;
  padding: 0 20px;
}

#submit-button {
  width: 20%;
  height: 40px;
  border: none;
  background-color: #777;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
#submit-button:hover {
  background-color: #999;
}

.dice-button {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 90%;
  background-color: #111; /* choose any color */
  color: white;
  font-size: 15px;
  text-align: center; /* align text to center */
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.dice-button:hover {
  background-color: #555;
}

#output-window {
  display: grid;
  place-items: center;
  font-size: 20px;
  border: 2px solid #111;
  width: 100px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.player-message {
  color: #4da6ff;
}
.combat-start-message {
  color: coral;
}
.api-message {
  color: #a5ff90;
}
.intro-message {
  color: #a5ff90;
}

#dice-roller-header {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 5px;
}

#side-bar {
  position: relative;
  width: 18%;
  max-height: 95vh;
  overflow-y: auto;
  background-color: #333;
  border: 1px solid #777;
  display: flex;
  flex-direction: column;
}
#socials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.social img {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
}

#instructions {
  flex: 7;
  overflow-y: scroll;
  padding: 5px;
}
*::-webkit-scrollbar {
  width: 10px;
  background-color: #555;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #999;
}
ul {
  list-style: none;
  margin-left: -25px;
}
li {
  margin: 3px;
}
li::before {
  content: "\2694";
  color: whitesmoke;
  margin-right: 3px;
}

#square-section {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border: 1px solid #777;
  margin-top: 3px;
}

.center-cell {
  text-align: center;
}

#signup-button {
  width: 100%;
  border: none;
  background-color: #777;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
#signup-button:hover {
  background-color: #999;
}

#overlay {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 40%;
  padding: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}
#overlay.active {
  background-color: rgba(0, 0, 0, 0.5);
}
#overlay button {
  height: 40px;
  width: 100%;
  border: none;
  background-color: #777;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}
#overlay button:hover {
  background-color: #999;
}
#overlay.active ~ #main-container {
  filter: brightness(0.5) blur(5px);
}
#signup-form {
  display: grid;
}
#signup-form input {
  background-color: #333;
  color: white;
  height: 20px;
  text-align: center;
}

#feedback-modal {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 40%;
  padding: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}
#feedback-modal.active {
  background-color: rgba(0, 0, 0, 0.5);
}
#feedback-modal.active ~ #main-container {
  filter: brightness(0.5) blur(5px);
}
#feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}
.feedback-button {
  background-color: #111;
  color: #999;
  margin-left: 3px;
  padding: 5px 4px;
  border: 1px solid #777;
  font-size: 12px;
  cursor: pointer;
}
#feedback-text,
#desired-completion {
  margin: 10px 0;
  width: 80%;
  height: 100px;
  border: 1px solid #555;
  border-radius: 5px;
  overflow-y: scroll;
  resize: none;
}
#submit-feedback-button,
#close-feedback-button {
  margin: 5px;
  border: none;
  background-color: #777;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  padding: 10px;
}
#submit-feedback-button:hover,
#close-feedback-button:hover {
  background-color: #999;
}

#feedback-category-div {
  margin: 10px;
}
#feedback-category {
  background-color: #111;
  color: #999;
  padding: 4px;
  border: 1px solid #777;
  font-size: 12px;
}

#instructions-header,
#combat-rules-header {
  text-align: center;
  margin: 0;
}
#banner-logo {
  max-width: 100%;
}
#logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#df-logo {
  align-self: center;
  max-width: 50%;
}
.combat-start-button {
  width: 100%;
  border: none;
  background-color: #777;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.combat-start-button:disabled {
  background-color: #555;
  color: #444;
  cursor: not-allowed;
}

/* Mobile stuff */
@media (max-width: 768px) {
  #chat-container {
    height: auto;
    width: 100%;
    margin-top: 5px;
  }
  #square-section {
    font-size: 1.5rem;
    margin: 3px 10px 10px 10px;
  }
  .dice-button {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.3rem;
  }
  #side-bar {
    width: 100%;
  }
  #chat-history {
    height: calc(98vh - 300px);
    flex: unset;
  }
  #chat-form {
    flex-direction: column;
    margin-top: 3px;
  }
  #chat-input {
    width: 100%;
    margin-bottom: 10px;
  }
  #submit-button {
    width: 100%;
  }
  #instructions {
    position: static;
    margin-top: 10px;
  }
  #overlay {
    width: 90%;
  }
  #feedback-modal {
    width: 90%;
  }
}
